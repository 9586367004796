* {
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
body {
  font-family: Inter;
}
